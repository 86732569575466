import Footer from "../components/Footer";
import HeroPages from "../components/HeroPages";
import PlanTrip from "../components/PlanTrip";


function About() {
  return (
    <>
      <section className="about-page">
        <HeroPages name="About" />
        <div className="container">
          <div className="about-main">
            
            <div className="about-main__text">
              <h3>About Company</h3>
              <h2>You start the engine and your adventure begins</h2>
              <p>
                Welcome to Perfect-Sunshine Company, your one-stop shop for all your mobile and car needs. We are a dedicated team of professionals committed to providing our customers with the highest quality products and exceptional service.
              </p>
              <p>
                Our mobile division offers the latest technology in smartphones, tablets, and other mobile devices. We carry a wide range of brands and models to suit every need and budget, and our knowledgeable staff is always available to help you find the perfect device to fit your lifestyle.
              </p>
              <p>

                At Sunshine Company, we also specialize in car sales, providing our customers with top-quality vehicles at competitive prices. Whether you're looking for a compact car for city driving or a spacious SUV for family road trips, we have a wide selection of makes and models to choose from.
              </p>
              <p>
                Our commitment to exceptional customer service doesn't end with the sale, however. We also offer a range of services to ensure that your vehicle stays in top condition, including maintenance and repair services. Our certified mechanics use only the highest quality parts and equipment to ensure that your car is running smoothly.
              </p>
              <p>

                At Sunshine Company, our mission is to provide our customers with the highest level of service and quality products in both mobile and car sales. We strive to create a welcoming and friendly atmosphere, where our customers feel valued and taken care of. Thank you for considering us for your mobile and car needs.

              </p>
              {/* <div className="about-main__text__icons">
                <div className="about-main__text__icons__box">
                  <img src={Box1} alt="car-icon" />
                  <span>
                    <h4>20</h4>
                    <p>Car Types</p>
                  </span>
                </div>
                <div>

                </div>
                <div className="about-main__text__icons__box">
                  <img src={Box2} alt="car-icon" />
                  <span>
                    <h4>85</h4>
                    <p>Rental Outlets</p>
                  </span>
                </div>
                <div className="about-main__text__icons__box">
                  <img src={Box3} alt="car-icon" className="last-fk" />
                  <span>
                    <h4>75</h4>
                    <p>Repair Shop</p>
                  </span>
                </div>
              </div> */}
            </div>
          </div>
          <PlanTrip />
        </div>
      </section>
      <div className="book-banner">
        <div className="book-banner__overlay"></div>
        <div className="container">
          <div className="text-content">
            <h2> getting in touch with us</h2>
            <span>
              <i className="fa-solid fa-phone"></i>
              <h3>(+971) 58877 8110</h3>
            </span>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default About;
