import iphone_14 from "../images/phone/iPhone-14-PNG-Photo.png";
import iphone_14_pro from "../images/phone/iPhone-14-Pro-Transparent.png";
import iphone_13 from "../images/phone/iPhone-13.png";
import iphone_13_pro from "../images/phone/iPhone-13-PNG-Picture.png";
import iphone_12 from "../images/phone/Apple-iPhone-12-PNG.png";
import iphone_12_pro from "../images/phone/iPhone-12_pro.png";
export const Mobil_DATA = [
  
    {
      name: "Iphone 14",
      // price: "37",
      img: iphone_14,
      Capacity: "128 GB, 256 GB, 512 GB, 1 TB",
      color:"black",
      
    },

  
    {
      name: "Iphone 14 pro",
      img: iphone_14_pro,
      Capacity: "128 GB, 256 GB, 512 GB, 1 TB",
      color:"black",
      
    },

    {
      name: "Iphone 13",
      img: iphone_13,
      Capacity: "128 GB, 256 GB, 512 GB, 1 TB",
      color:"red",
      
    },
  
    {
      name: "Iphone 13 pro",
      img: iphone_13_pro,
      Capacity: "128 GB, 256 GB, 512 GB, 1 TB",
      color:"gold",
      
    },

    {
      name: "Iphone 12",
      img: iphone_12 ,
      Capacity: "128 GB, 256 GB, 512 GB, 1 TB",
      color:"red",
      
    },
    {
      name: "Iphone 12 pro",
      img: iphone_12_pro ,
      Capacity: "128 GB, 256 GB, 512 GB, 1 TB",
      color:"red",
      
    },
    
];
