
import HeroCar from "../images/hero/main-car.png";
import iphoneBanner from "../images/hero/iphone-11.png";
import { useEffect, useState } from "react";

function Hero() {
  const [goUp, setGoUp] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({ top: (0, 0), behavior: "smooth" });
  };

  

  useEffect(() => {
    const onPageScroll = () => {
      if (window.pageYOffset > 600) {
        setGoUp(true);
      } else {
        setGoUp(false);
      }
    };
    window.addEventListener("scroll", onPageScroll);

    return () => {
      window.removeEventListener("scroll", onPageScroll);
    };
  }, []);
  return (
    <>
      <section id="home" className="hero-section">
     
<div id="carouselExampleControls" class=" carousel slide  carousel-dark" data-mdb-ride="carousel">
  <div class="container carousel-inner">
   
    <div className="carousel-item   active">
          {/* <img className="bg-shape" src={BgShape} alt="bg-shape" /> */}
          <div className="hero-content">
            <div className="hero-content__text">
              <h4>Own your dream car for less!</h4>
              <h1>Get behind the <span>wheel</span>  of your dream car for less!

              </h1>
              <p>
                Our dealership offers affordable prices on a wide selection of high-quality vehicles. Visit us today and drive away in style!.
              </p>
              
            </div>

            {/* img */}
            <img
              src={HeroCar}
              alt="car-img"
              className="hero-content__car-img"
            />
          </div>
        </div>
     
     
   
        <div className=" carousel-item">
          {/* <img className="bg-shape" src={BgShape} alt="bg-shape" /> */}
          <div className="hero-content">
            <div className="hero-content__text">
              <h4>Buy iPhones with confidence and save big!</h4>
              <h1> Get the best  <span>high-quality iPhones</span> shop with confidence!
              
              </h1>
              <p>
              We offer a wide range of the latest iPhone models, all at competitive prices. Whether you're in the market for a brand new iPhone or a refurbished one, we have something for everyone.
              </p>
              
            </div>

            {/* img */}
            <img
              src={iphoneBanner}
              alt="car-img"
             
              className="hero-content__mobil-img"
            />
          </div>

         
        </div>
    
  </div>
  <button class="carousel-control-prev" type="button" data-mdb-target="#carouselExampleControls"
    data-mdb-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-mdb-target="#carouselExampleControls"
    data-mdb-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>


       

        {/* page up */}
        <div
          onClick={scrollToTop}
          className={`scroll-up ${goUp ? "show-scroll" : ""}`}
        >
          <i className="fa-solid fa-angle-up"></i>
        </div>
      </section>
    </>
  );
}

export default Hero;
