function Footer() {
  return (
    <>
      <footer>
        <div className="container">
          <div className="footer-content">
            <ul className="footer-content__1">
              <li>
                <span>Perfect</span> Sunshine
              </li>
              <li>
                We offers a big range of vehicles for all your driving needs. We
                have the perfect car to meet your needs.
              </li>
             
              <li>
              <a href="tel:+971 58877 8110">
              
                  <i className="fa-solid fa-phone"></i> &nbsp; +971 58877 8110
                </a>
              </li>
              <li>
              <a href="tel:+971568454936">
              
                  <i className="fa-solid fa-phone"></i> &nbsp; +971 56845 4936
                </a>
              </li>
              <li>
                <a href="tel:+971 4 835 8011">
                <i className="fa-solid fa-phone"></i> &nbsp; +971 4 835 8011
                </a>
                
              </li>
              
              <li>
                <a
                  href="mailto: 
               info@perfect-sunshine.com"
                >
                  <i className="fa-solid fa-envelope"></i>
                  &nbsp; info@perfect-sunshine.com
                </a>
              </li>

              {/* <li>
                <a
                  style={{ fontSize: "14px" }}
                  target="_blank"
                  rel="noreferrer"
                  href="https://jephunneh.com/"
                >
                  Design by cjephuneh
                </a>
              </li> */}
            </ul>

            <ul className="footer-content__2">
              <li>Company</li>
              <li>
                <a href="#home">Dubai</a>
              </li>
              <li>
                <a href="#home">Careers</a>
              </li>
              <li>
                <a href="#home">Mobile</a>
              </li>
              <li>
                <a href="#home">Blog</a>
              </li>
              <li>
                <a href="#home">How we work</a>
              </li>
            </ul>

            <ul className="footer-content__2">
              <li>Working Hours</li>
              <li>24/7</li>
              {/* <li>Sat: 9:00AM - 19:00PM</li>
              <li>Sun: Closed</li> */}
            </ul>

            <ul className="footer-content__2">
              <li>Subscription</li>
              <li>
                <p>Subscribe your Email address for latest news & updates.</p>
              </li>
              <li>
                <input type="email" placeholder="Enter Email Address"></input>
              </li>
              <li>
                <button className="submit-email">Submit</button>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
