import Footer from "../components/Footer";
import HeroPages from "../components/HeroPages";

import { Link } from "react-router-dom";
import { Mobil_DATA } from "../components/Mobildata";


function MobilModels() {
  
  return (
    <>
      <section className="models-section">
        <HeroPages name="Mobile " />
        <div className="container">
          <div className="models-div">
            {
              Mobil_DATA?.map((data)=>(
                <div className="models-div__box">
                <div className="models-div__box__img">
                  <img src={data.img} alt="car_img" />
                  <div className="models-div__box__descr">
                    <div className="models-div__box__descr__name-price">
                      <div className="models-div__box__descr__name-price__name">
                        <p>{data.name}</p>
                        
                      </div>
                     
                    </div>
                    <div className="models-div__box__descr__name-price__details">
                      <span>
                        Capacity
                      </span>
                      <span style={{ textAlign: "right" }}>
                        {data.Capacity} &nbsp; 
                      </span>
                      <span>
                       Color
                      </span>
                      <span style={{ textAlign: "right" }}>
                       {data.color} 
                      </span>
                    </div>
                    <div className="models-div__box__descr__name-price__btn">
                      <Link onClick={() => window.scrollTo(0, 0)} to="/contact">
                        Contect Us
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              ))
            } 
          </div>
        </div>
        <div className="book-banner">
          <div className="book-banner__overlay"></div>
          <div className="container">
            <div className="text-content">
              <h2>By getting in touch with us</h2>
              <span>
                <i className="fa-solid fa-phone"></i>
                <h3>(+971) 58877 8110</h3>
              </span>
            </div>
          </div>
        </div>
        <Footer />
      </section>
    </>
  );
}

export default MobilModels;
